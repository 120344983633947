import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/dictionary';

export default {
    get_katottg(payload) {
        return axios.get(`${RESOURCE_NAME}/katottg`, { params: payload })
    },
    create_city(payload) {
        return axios.post(`${RESOURCE_NAME}/katottg/create-city`, payload)
    }
};