<template>
  <v-container class="fill-height pa-0" fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card max-width="1050" class="mx-auto">
          <v-toolbar class="grey lighten-5 toolbar-custom" elevation="0"
                     style="background-color: #f7f7f7 !important;
                     border-bottom: 2px solid #e8e8e8 !important;">
            <v-toolbar-title style="font-size: 1.3rem">
              Довідник КАТОТТГ
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-2 pb-14">
            <v-form ref="form">
              <v-row>
                <v-col cols="7">
                  <v-select :items="regions" filled
                            label="Область/Місто спец. значення" color="grey"
                            hide-details v-model="selected_region.id"
                            clearable
                            @change="onRegionSelect"
                  />
                </v-col>
                <v-col cols="5">
                  <div class="data-container" v-if="selected_region.data">
                    <div class="data-type">
                      <div class="label-data">Тип</div>
                      <div class="text-data">{{ selected_region.data.type }} <span>({{ getFullType(selected_region.data.type) }})</span></div>
                    </div>
                    <div class="data-code">
                      <div class="label-data">Код КАТОТТГ</div>
                      <div class="text-data">
                        {{ selected_region.data.code }}
                      </div>
                    </div>
                    <div class="data-btn">
                      <v-btn icon class="ml-2 mt-2 grey lighten-4" @click="copyCode(selected_region)">
                        <v-icon color="grey darken-2" size="18">
                          mdi-content-copy
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col v-if="!only_city_regions" cols="7">
                  <v-select :items="districts" filled
                            label="Район" color="grey"
                            clearable
                            hide-details v-model="selected_district.id"
                            @change="onDistrictSelect"
                  />
                </v-col>
                <v-col v-if="!only_city_regions" cols="5">
                  <div class="data-container" v-if="selected_district.data">
                    <div class="data-type">
                      <div class="label-data">Тип</div>
                      <div class="text-data">{{ selected_district.data.type }} <span>({{ getFullType(selected_district.data.type) }})</span></div>
                    </div>
                    <div class="data-code">
                      <div class="label-data">Код КАТОТТГ</div>
                      <div class="text-data">
                        {{ selected_district.data.code }}
                      </div>
                    </div>
                    <div class="data-btn">
                      <v-btn icon class="ml-2 mt-2 grey lighten-4" @click="copyCode(selected_district)">
                        <v-icon color="grey darken-2" size="18">
                          mdi-content-copy
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col v-if="!only_city_regions" cols="7">
                  <v-select :items="hromadas" filled
                            label="Територіальна громада" color="grey"
                            hide-details v-model="selected_hromada.id"
                            clearable
                            @change="onHromadaSelect"
                  />
                </v-col>
                <v-col v-if="!only_city_regions" cols="5">
                  <div class="data-container" v-if="selected_hromada.data">
                    <div class="data-type">
                      <div class="label-data">Тип</div>
                      <div class="text-data">{{ selected_hromada.data.type }} <span>({{ getFullType(selected_hromada.data.type) }})</span></div>
                    </div>
                    <div class="data-code">
                      <div class="label-data">Код КАТОТТГ</div>
                      <div class="text-data">
                        {{ selected_hromada.data.code }}
                      </div>
                    </div>
                    <div class="data-btn">
                      <v-btn icon class="ml-2 mt-2 grey lighten-4" @click="copyCode(selected_hromada)">
                        <v-icon color="grey darken-2" size="18">
                          mdi-content-copy
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col v-if="!only_city_regions" cols="7">
                  <v-select :items="cities" filled
                            label="Населений пункт" color="grey"
                            hide-details v-model="selected_city.id"
                            clearable
                            @change="onCitySelect"
                  />
                </v-col>
                <v-col v-if="!only_city_regions" cols="5">
                  <div class="data-container" v-if="selected_city.data">
                    <div class="data-type">
                      <div class="label-data">Тип</div>
                      <div class="text-data">{{ selected_city.data.type }} <span>({{ getFullType(selected_city.data.type) }})</span></div>
                    </div>
                    <div class="data-code">
                      <div class="label-data">Код КАТОТТГ</div>
                      <div class="text-data">
                        {{ selected_city.data.code }}
                      </div>
                    </div>
                    <div class="data-btn">
                      <v-btn icon class="ml-2 mt-2 grey lighten-4" @click="copyCode(selected_city)">
                        <v-icon color="grey darken-2" size="18">
                          mdi-content-copy
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-select :items="city_regions" filled
                            label="Район населеного пункту" color="grey"
                            hide-details v-model="selected_city_region.id"
                            clearable
                            @change="onCityRegionSelect"
                  />
                </v-col>
                <v-col cols="5">
                  <div class="data-container" v-if="selected_city_region.data">
                    <div class="data-type">
                      <div class="label-data">Тип</div>
                      <div class="text-data">{{ selected_city_region.data.type }} <span>({{ getFullType(selected_city_region.data.type) }})</span></div>
                    </div>
                    <div class="data-code">
                      <div class="label-data">Код КАТОТТГ</div>
                      <div class="text-data">
                        {{ selected_city_region.data.code }}
                      </div>
                    </div>
                    <div class="data-btn">
                      <v-btn icon class="ml-2 mt-2 grey lighten-4" @click="copyCode(selected_city_region)">
                        <v-icon color="grey darken-2" size="18">
                          mdi-content-copy
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="7" class="pb-1">
                  <v-alert type="success" outlined rounded>
                    {{ full_name }}
                  </v-alert>
                </v-col>
                <v-col cols="7" class="pt-1">
                  <v-btn depressed block outlined color="grey darken-1" :disabled="!selected_city.id && this.selected_region.data?.type !== 'K'" :loading="loading"
                         @click="createCity">
                    <v-icon left>mdi-plus</v-icon>
                    Створити населений пункт
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import dictionaryAPI from "@/utils/axios/dictionary"
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "DictionaryKatottg",
  data() {
    return {
      full_name: 'Для відображення інформації оберіть необхідні поля',
      regions: [],
      districts: [],
      hromadas: [],
      cities: [],
      city_regions: [],
      only_city_regions: false,
      selected_region: {id: null, data: null},
      selected_district: {id: null, data: null},
      selected_hromada: {id: null, data: null},
      selected_city: {id: null, data: null},
      selected_city_region: {id: null, data: null},
      loading: false
    }
  },
  methods: {
    generateFullName() {
      const name_arr = []
      if (this.selected_region.data.type === 'K') {
        if (this.selected_city_region.id) {
          name_arr.push(
              `м. ${this.selected_region.data.name}`
          )
          name_arr.push(
              `${this.selected_city_region.data.name} район`
          )
        } else {
          name_arr.push(
              `м. ${this.selected_region.data.name}`
          )
        }
      } else {
        if (this.selected_region.id) {
          name_arr.push(
              `${this.selected_region.data.name} обл.`
          )
        }
        if (this.selected_district.id) {
          name_arr.push(
              `${this.selected_district.data.name} р-н.`
          )
        }
        if (this.selected_hromada.id) {
          name_arr.push(
              `${this.selected_hromada.data.name} територіальна громада`
          )
        }
        if (this.selected_city.id) {
          name_arr.push(
              `${this.getShortType(this.selected_city.data.type)} ${this.selected_city.data.name}`
          )
        }
        if (this.selected_city_region.id) {
          name_arr.push(
              `${this.selected_city_region.data.name} район`
          )
        }
      }
      this.full_name = name_arr.join(', ')

      if (!this.full_name) {
        this.full_name = 'Для відображення інформації оберіть необхідні поля'
      }
    },
    copyToClipboard(str) {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText)
        return navigator.clipboard.writeText(str);
      return Promise.reject('The Clipboard API is not available.');
    },
    copyCode(payload) {
      if (!payload || !payload.id) return

      let alert_text = ''
      let copy_value = payload.data.code

      if (payload.data.type === 'O') {
        alert_text = `Код ${payload.data.name} область (${payload.data.code}). Успішно скопійовано до буферу обміну`
      }
      if (payload.data.type === 'K') {
        alert_text = `Код міста ${payload.data.name} (${payload.data.code}). Успішно скопійовано до буферу обміну`
      }
      if (payload.data.type === 'P') {
        alert_text = `Код ${payload.data.name} район (${payload.data.code}). Успішно скопійовано до буферу обміну`
      }
      if (payload.data.type === 'H') {
        alert_text = `Код ${payload.data.name} територіальна громада (${payload.data.code}). Успішно скопійовано до буферу обміну`
      }
      if (payload.data.type === 'M') {
        alert_text = `Код міста ${payload.data.name} (${payload.data.code}). Успішно скопійовано до буферу обміну`
      }
      if (payload.data.type === 'T') {
        alert_text = `Код селища міського типу ${payload.data.name} (${payload.data.code}). Успішно скопійовано до буферу обміну`
      }
      if (payload.data.type === 'C') {
        alert_text = `Код села ${payload.data.name} (${payload.data.code}). Успішно скопійовано до буферу обміну`
      }
      if (payload.data.type === 'X') {
        alert_text = `Код селища/смт. ${payload.data.name} (${payload.data.code}). Успішно скопійовано до буферу обміну`
      }
      if (payload.data.type === 'B') {
        alert_text = `Код району нас. пункту ${payload.data.name} (${payload.data.code}). Успішно скопійовано до буферу обміну`
      }
      navigator.clipboard.writeText(copy_value);
      this.copyToClipboard(copy_value)
          .then(() => {
            this.$store.commit(ALERT_SHOW, {text: alert_text, color: 'success'})
          })
          .catch(() => {
            this.$store.commit(ALERT_SHOW, {text: 'Не вдалось записати дані до буферу обміну. Можливо немає дозволу', color: 'error'})
          })
    },
    getFullType(type) {
      switch (type) {
        case 'O':
          return 'Область'
        case 'K':
          return 'Місто спец. статус'
        case 'P':
          return 'Район'
        case 'H':
          return 'Тер. громада'
        case 'M':
          return 'Місто'
        case 'T':
          return 'Селище міського типу'
        case 'C':
          return 'Село'
        case 'X':
          return 'Селище/смт.'
        case 'B':
          return 'Район нас. пункту'
      }
    },
    getShortType(type) {
      switch (type) {
        case 'O':
          return 'обл. '
        case 'K':
          return 'м. '
        case 'P':
          return 'р-н. '
        case 'H':
          return 'т. г. '
        case 'M':
          return 'м. '
        case 'T':
          return 'смт. '
        case 'C':
          return 'с. '
        case 'X':
          return 'селище/смт. '
        case 'B':
          return 'район '
      }
    },
    onRegionSelect(payload) {
      this.$nextTick(() => {
        this.only_city_regions = false
        if (this.selected_district.id !== payload) {
          this.selected_district = {id: null, data: null}
          this.selected_hromada = {id: null, data: null}
          this.selected_city = {id: null, data: null}
          this.selected_city_region = {id: null, data: null}
        } else {
          if (this.selected_district.id) {
            return
          }
        }

        if (!payload) {
          this.selected_region.data = null
          this.selected_district = {id: null, data: null}
          this.selected_hromada = {id: null, data: null}
          this.selected_city = {id: null, data: null}
          this.selected_city_region = {id: null, data: null}
        } else {
          this.selected_city_region = {id: null, data: null}
          const row = this.regions.find(i => i.value === payload)
          if (row) {
            this.selected_region.data = {type: row.type, code: row.text_field_1, name: row.text_field_2}
            if (row.type === 'K') {
              this.only_city_regions = true

              this.getCityRegions(this.selected_region.id)
            }
          }
          this.getDistricts()
        }

        this.generateFullName()
      })
    },
    onDistrictSelect(payload) {
      this.$nextTick(() => {
        if (this.selected_hromada.id !== payload) {
          this.selected_hromada = {id: null, data: null}
          this.selected_city = {id: null, data: null}
          this.selected_city_region = {id: null, data: null}
        } else {
          if (this.selected_hromada.id) {
            return
          }
        }

        if (!payload) {
          this.selected_hromada = {id: null, data: null}
          this.selected_city = {id: null, data: null}
          this.selected_city_region = {id: null, data: null}
        } else {
          const row = this.districts.find(i => i.value === payload)
          if (row) {
            this.selected_district.data = {type: row.type, code: row.text_field_1, name: row.text_field_2}
          }
          this.getHromadas()
        }
        this.generateFullName()
      })
    },
    onHromadaSelect(payload) {
      this.$nextTick(() => {
        if (this.selected_city.id !== payload) {
          this.selected_city = {id: null, data: null}
          this.selected_city_region = {id: null, data: null}
        } else {
          if (this.selected_city.id) {
            return
          }
        }

        if (!payload) {
          this.selected_city = {id: null, data: null}
          this.selected_city_region = {id: null, data: null}
        } else {
          const row = this.hromadas.find(i => i.value === payload)
          if (row) {
            this.selected_hromada.data = {type: row.type, code: row.text_field_1, name: row.text_field_2}
          }
          this.getCities()
        }
        this.generateFullName()
      })
    },
    onCitySelect(payload) {
      this.$nextTick(() => {
        if (this.selected_city_region.id !== payload) {
          this.selected_city_region = {id: null, data: null}
        } else {
          if (this.selected_city_region.id) {
            return
          }
        }

        if (!payload) {
          this.selected_city_region = {id: null, data: null}
        } else {
          const row = this.cities.find(i => i.value === payload)
          if (row) {
            this.selected_city.data = {type: row.type, code: row.text_field_1, name: row.text_field_2}
          }
          this.getCityRegions()
        }
        this.generateFullName()
      })
    },
    onCityRegionSelect(payload) {
      this.$nextTick(() => {
        if (payload) {
          const row = this.city_regions.find(i => i.value === payload)
          if (row) {
            this.selected_city_region.data = {type: row.type, code: row.text_field_1, name: row.text_field_2}
          }
        } else {
          this.selected_city_region.data = null
        }
        this.generateFullName()
      })
    },
    getDistricts() {
      dictionaryAPI.get_katottg(
          {
            parent_id: this.selected_region.id,
            level: 2
          }
      )
          .then((response) => response.data)
          .then(data => this.districts = data)
    },
    getHromadas() {
      dictionaryAPI.get_katottg(
          {
            parent_id: this.selected_district.id,
            level: 3
          }
      )
          .then((response) => response.data)
          .then(data => this.hromadas = data)
    },
    getCities() {
      dictionaryAPI.get_katottg(
          {
            parent_id: this.selected_hromada.id,
            level: 4
          }
      )
          .then((response) => response.data)
          .then(data => this.cities = data)
    },
    getCityRegions(parent_id=null) {
      dictionaryAPI.get_katottg(
          {
            parent_id: parent_id || this.selected_city.id,
            level: 5
          }
      )
          .then((response) => response.data)
          .then(data => this.city_regions = data)
    },

    createCity() {
      const payload = {
        region_name: this.selected_region.data?.name || null,
        district_name: this.selected_district.data?.name || null,
        hromada_name: this.selected_hromada.data?.name || null,
        hromada_code: this.selected_hromada.data?.code || null,
        city_name: this.selected_city.data?.name || null,
        city_code: this.selected_city.data?.code || null,
        city_type: this.selected_city.data?.type || null,
        city_region_name: this.selected_city_region.data?.name || null,
        city_region_code: this.selected_city_region.data?.code || null
      }

      if (this.selected_region.data.type === 'K') {
        payload.region_name = null
        payload.district_name = null
        payload.hromada_name = null
        payload.hromada_code = null
        payload.city_name = this.selected_region.data?.name || null
        payload.city_code = this.selected_region.data?.code || null
        payload.city_type = this.selected_region.data?.type || null
      }

      this.loading = true

      dictionaryAPI.create_city(payload)
          .then(response => response.data)
          .then(data => {
            this.$emit('afterCityCreate', data)
            this.$store.commit(ALERT_SHOW, {text: 'Операція успішна. Дані уже відображаються у списку', color: 'success'})

            this.selected_region = {id: null, data: null}
            this.selected_district = {id: null, data: null}
            this.selected_hromada = {id: null, data: null}
            this.selected_city = {id: null, data: null}
            this.selected_city_region = {id: null, data: null}

            this.districts = []
            this.hromadas = []
            this.cities = []
            this.city_regions = []

            this.full_name = 'Для відображення інформації оберіть необхідні поля'
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
          .finally(() => {
            this.loading = false
          })
    },
  },
  created() {
    dictionaryAPI.get_katottg()
        .then(response => response.data)
        .then(data => {
          this.regions = data
        })
  }
}
</script>

<style scoped lang="scss">
  .data-container {
    display: flex;
    align-items: center;

    .label-data {
      font-size: .7rem;
      font-weight: bold;
      color: var(--v-success-base);
    }

    .text-data {
      font-size: 1.03rem;
      font-weight: bold;
      line-height: 1rem;

      span {
        font-size: .86rem;
        font-weight: normal;
      }
    }

    .data-btn {
      flex: 0 0 36px;
    }

    .data-type {
      flex: 0 0 165px;
    }
    .data-code {
      flex: 1;
      .text-data {
        font-weight: normal;
      }
    }
  }
</style>